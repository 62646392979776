.app-root {
  display: flex;
  justify-content: center;
}

.app-container {
  max-width: 1440px;
  min-width: 360px;
}

.services {
  /* background-color: #edf2eb; */
  background-color: white;
}
